import request from './index'

export const brandList = (data) => {
  return request({
    method: 'GET',
    url: '/client/list',
    params: data
  })
}

export const brandDelete = (data) => {
  return request({
    method: 'PUT',
    url: '/client/status',
    data: data
  })
}

export const brandAdd = (data) => {
  return request({
    method: 'POST',
    url: '/client/add',
    data: data
  })
}

export const brandUpdate = (data) => {
  return request({
    method: 'PUT',
    url: '/client/update',
    data: data
  })
}
export const feedback = (data) => {
  return request({
    method: 'PUT',
    url: '/client/feedback',
    data: data
  })
}
