<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-2"
            inline
          >

            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchType"
              :options="searchOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Search Type</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchValue"
              placeholder="Search Input"
            ></b-form-input>
            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.company_name"
              placeholder="Brand Name"
            ></b-form-input>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.client_type"
              :options="brandOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Brand</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="filter.authenticity"
              class="mb-2 mr-sm-2 mb-sm-0"
            >
              <b-form-select-option :value="null">Feedback Type</b-form-select-option>
              <b-form-select-option :value="1">Good</b-form-select-option>
              <b-form-select-option :value="0">Bad</b-form-select-option>
            </b-form-select>
            <b-form-select
              v-model="filter.feedback"
              class="mb-2 mr-sm-2 mb-sm-0"
            >
              <b-form-select-option :value="null">Follow Up</b-form-select-option>
              <b-form-select-option :value="1">Done</b-form-select-option>
              <b-form-select-option :value="0">Pending</b-form-select-option>
            </b-form-select>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
          </b-form>
        </div>
        <b-overlay
          :show="exportLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="!exportLoading"
        >
          <b-dropdown
            class="ml-auto"
            variant="outline-white"
            right
            no-caret
          >
            <template #button-content>
              <b-icon icon="list"></b-icon>
            </template>
            <b-dropdown-item @click="exportdata()">
              <b-icon icon="download"></b-icon>
              Export
            </b-dropdown-item>
            <b-dropdown-item @click="addmodal">
              <b-icon icon="person-plus"></b-icon>
              Add Brand
            </b-dropdown-item>
          </b-dropdown>
        </b-overlay>
      </div>

      <hr />

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!tabledata.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(basic)="data">
          <a
            :href="data.value.company_url"
            target="_blank"
            class="meta d-flex justify-content-start align-items-center"
          >
            <img :src="data.value.avatar ? data.value.avatar : `https://ui-avatars.com/api/?background=F54153&color=fff&name=${!data.value.company_name ? 'NA': data.value.company_name}`" />
            <p class="ml-2">
              <strong>{{ data.value.company_name }}</strong>
            </p>
          </a>
        </template>>
        <template #cell(name)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(mobile)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(feedback)="data">
          <div class="d-flex justify-content-start">
            <b-icon
              font-scale="2.0"
              :icon="data.item.feedback  ? 'check2-circle' : 'x-circle'"
              :variant="data.item.feedback ? 'success' :'danger' "
            ></b-icon>
          </div>
        </template>
        <template #cell(feedbackText)="data">
          <div style="min-width:200px; max-width:300px;">
            {{ data.item.feedback }}
          </div>
        </template>
        <template #cell(authenticity)="data">
          <div class="d-flex justify-content-start">
            {{ data.item.authenticity === 1 ? 'Good' : data.item.authenticity === 0 ? 'Bad' : '-' }}
          </div>
        </template>
        <template #cell(email)="data">
          {{ data.value || 'NA'}}
        </template>
        <template #cell(add_time)="data">
          {{ data.value | dateFormat}}
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              target="_blank"
              @click="viewFeedback(data.item)"
            >
              <b-icon icon="chat-text"></b-icon>
              Feedback
            </b-dropdown-item>
            <b-dropdown-item @click="editmodal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteBrand(data.item.client_id)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 50 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <!-- AddBrand Modal  -->
      <b-modal
        title="Add Brand"
        ref="addModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>

            <b-form-group label="Brand Name">
              <validation-provider
                #default="{ errors }"
                name="Brand Name"
                rules="required"
              >
                <b-form-input
                  v-model="addBrand.company_name"
                  placeholder="Brand Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile No">
              <validation-provider
                #default="{ errors }"
                name="Mobile No"
                :rules="{ required: true, regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addBrand.mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile Number"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="addBrand.email"
                  placeholder="Email Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{required: true, regex: /^[a-zA-Z\s]*$/}"
              >
                <b-form-input
                  v-model="addBrand.name"
                  placeholder="Enter Person Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Legal Name">
              <validation-provider
                #default="{ errors }"
                name="Legal Name"
              >
                <b-form-input
                  v-model="addBrand.legal_name"
                  placeholder="Legal Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Company Url">
              <validation-provider
                #default="{ errors }"
                name="Company Url"
                :rules="{regex: /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/}"
              >
                <b-form-input
                  v-model="addBrand.company_url"
                  placeholder="Company Url"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="GST No">
              <validation-provider
                #default="{ errors }"
                name="GST No"
                :rules="{regex: /^[a-zA-Z0-9]{15}$/}"
              >
                <b-form-input
                  v-model="addBrand.gstin"
                  placeholder="GST Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Pan No">
              <validation-provider
                #default="{ errors }"
                name="Pan No"
                :rules="{regex: /^[a-zA-Z0-9]{10}$/}"
              >
                <b-form-input
                  v-model="addBrand.pan"
                  placeholder="Pan Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Address">
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  v-model="addBrand.address"
                  placeholder="Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetBrand"
              >Reset</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="addbrand"
              >Add</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- EditBrand Modal  -->
      <b-modal
        title="Edit Brand"
        ref="editModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>

            <b-form-group label="Brand Name">
              <validation-provider
                #default="{ errors }"
                name="Brand Name"
                rules="required"
              >
                <b-form-input
                  v-model="editBrand.company_name"
                  placeholder="Brand Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile No">
              <validation-provider
                #default="{ errors }"
                name="Mobile No"
                :rules="{ required: true, regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="editBrand.mobile"
                  :disabled="editBrand.client_type ? true : false"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile Number"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="editBrand.email"
                  placeholder="Email Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{required: true, regex: /^[a-zA-Z\s]*$/}"
              >
                <b-form-input
                  v-model="editBrand.name"
                  placeholder="Enter Person Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Legal Name">
              <validation-provider
                #default="{ errors }"
                name="Legal Name"
              >
                <b-form-input
                  v-model="editBrand.legal_name"
                  placeholder="Legal Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Company Url">
              <validation-provider
                #default="{ errors }"
                name="Company Url"
                :rules="{regex: /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/}"
              >
                <b-form-input
                  v-model="editBrand.company_url"
                  placeholder="Company Url"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="GST No">
              <validation-provider
                #default="{ errors }"
                name="GST No"
                :rules="{regex: /^[a-zA-Z0-9]{15}$/}"
              >
                <b-form-input
                  v-model="editBrand.gstin"
                  placeholder="GST Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Pan No">
              <validation-provider
                #default="{ errors }"
                name="Pan No"
                :rules="{regex: /^[a-zA-Z0-9]{10}$/}"
              >
                <b-form-input
                  v-model="editBrand.pan"
                  placeholder="Pan Number"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Address">
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  v-model="editBrand.address"
                  placeholder="Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateBrand"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Add Feedback Modal -->
      <b-modal
        :title="`${'Client Feedback ('}${addFeedback.company_name})`"
        ref="feedbackModal"
        hide-footer
        :no-close-on-backdrop="true"
      >
        <!-- <h6>Client Name: {{addFeedback.company_name}}</h6> -->
        <validation-observer ref="feedRules">
          <b-form>
            <b-form-group label="Feedback">
              <validation-provider
                #default="{ errors }"
                name="Client Feedback"
                rules="required"
              >
                <b-form-textarea
                  v-model="addFeedback.feedback"
                  rows="4"
                  placeholder="Client Feedback"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="addFeedback.authenticity"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <b-form-radio :value="1">Good</b-form-radio>
                <b-form-radio :value="0">Bad</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <div class="d-flex justify-content-end">
              <b-overlay
                :show="loading2"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="!loading2"
              >
                <b-button
                  pill
                  type="submit"
                  variant="primary"
                  @click.prevent="submitFeed"
                >Submit</b-button></b-overlay>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { brandList, brandDelete, brandAdd, brandUpdate, feedback } from '@/api/brand'
export default {
  data () {
    return {
      perPage: 50,
      currentPage: 1,
      feedbackModal: false,
      addFeedback: {},
      editModal: false,
      addModal: false,
      tabledata: [],
      loading: true,
      exportLoading: false,
      loading2: false,
      items: [],
      total: 0,
      filter: {
        searchType: null,
        searchValue: null,
        company_name: null,
        client_type: null,
        authenticity: null,
        feedback: null,
        p: 1,
        size: 50
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'basic', label: 'Brand' },
        { key: 'legal_name', label: 'Legal Name' },
        { key: 'name', label: 'Name' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'email', label: 'Email' },
        // { key: 'gstin', label: 'GST' },
        // { key: 'pan', label: 'Pan' },
        // { key: 'address', label: 'Address' },
        { key: 'feedback', label: 'Follow Up' },
        { key: 'feedbackText', label: 'Feedback' },
        { key: 'authenticity', label: 'Authenticity' },
        { key: 'add_time', label: 'Date Added' },
        'action'
      ],
      editBrand: {
      },
      addBrand: {
        mobile: null,
        name: null,
        email: null,
        company_name: null,
        company_url: null,
        legal_name: null,
        gstin: null,
        pan: null,
        address: null
      },
      searchOptions: [
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ],
      brandOptions: [
        { value: 0, text: 'Admin brand' },
        { value: 1, text: 'Signup brand' }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    exportdata () {
      this.exportLoading = true
      var influencerWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, influencerWS, 'BrandData')
      setTimeout(() => {
        XLSX.writeFile(wb, 'Brand.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        searchType: null,
        searchValue: null,
        company_name: null,
        client_type: null,
        authenticity: null,
        feedback: null,
        p: 1,
        size: 50
      }
      this.currentPage = 1
      this.getData()
    },
    viewFeedback (item) {
      this.addFeedback = {
        feedback: item.feedback,
        client_id: item.client_id,
        company_name: item.company_name,
        authenticity: item.authenticity
      }
      this.$refs.feedbackModal.show()
    },
    submitFeed () {
      this.$refs.feedRules.validate().then(async success => {
        if (success) {
          try {
            const data = {
              actionUser: window.localStorage.getItem('user-name'),
              client_id: this.addFeedback.client_id,
              feedback: this.addFeedback.feedback,
              authenticity: this.addFeedback.authenticity
            }
            this.loading2 = true
            const response = await feedback(data)
            if (response.succ) {
              this.$bvToast.toast('Feedback Added', {
                title: 'Add',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              const index = this.tabledata.findIndex(a => a.client_id === this.addFeedback.client_id)
              this.items[index].feedback = this.addFeedback.feedback
              this.items[index].authenticity = this.addFeedback.authenticity
              this.$refs.feedbackModal.hide()
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Feedback error!',
                variant: 'danger',
                solid: true
              })
            }
            this.loading2 = false
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    resetBrand () {
      this.addBrand = {
        mobile: null,
        name: null,
        email: null,
        company_name: null,
        company_url: null,
        legal_name: null,
        gstin: null,
        pan: null,
        address: null
      }
    },
    addmodal () {
      this.$refs.addModal.show()
    },
    editmodal (item) {
      this.editBrand = {
        company_name: item.basic.company_name,
        client_type: item.client_type,
        mobile: item.mobile,
        client_id: item.client_id,
        name: item.name,
        company_url: item.basic.company_url,
        gstin: item.gstin,
        pan: item.pan,
        legal_name: item.legal_name,
        address: item.address,
        email: item.email
      }
      this.$refs.editModal.show()
    },
    cancelAdd () {
      this.resetBrand()
      this.$refs.addModal.hide()
    },
    cancelEdit () {
      this.$refs.editModal.hide()
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      this.loading = true
      const response = await brandList(this.filter)
      if (response.succ) {
        this.total = response.totaluser
        this.tabledata = response.data
        this.items = response.data.map(item => {
          return {
            basic: {
              company_name: item.company_name,
              avatar: item.avatar,
              company_url: item.company_url
            },
            mobile: item.mobile,
            email: item.email,
            name: item.name,
            client_id: item.client_id,
            is_active: item.is_active,
            add_time: item.add_time,
            gstin: item.gstin,
            pan: item.pan,
            address: item.address,
            legal_name: item.legal_name,
            feedback: item.feedback,
            authenticity: item.authenticity,
            client_type: item.client_type,
            company_name: item.company_name
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async deleteBrand (clientId) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this brand?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            client_id: clientId,
            is_active: 2
          }
          if (value) {
            const response = await brandDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Brand deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.items.splice(this.tabledata.findIndex(a => a.client_id === clientId), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((error) => {
          console.log('cancel delete. error', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateBrand () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this brand?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                client_id: this.editBrand.client_id,
                email: this.editBrand.email,
                mobile: this.editBrand.mobile,
                name: this.editBrand.name,
                company_name: this.editBrand.company_name,
                company_url: this.editBrand.company_url,
                gstin: this.editBrand.gstin,
                pan: this.editBrand.pan,
                legal_name: this.editBrand.legal_name,
                address: this.editBrand.address
              }
              if (value) {
                const response = await brandUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('Brand updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel update.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addbrand () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this brand?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                email: this.addBrand.email,
                mobile: this.addBrand.mobile,
                name: this.addBrand.name,
                company_name: this.addBrand.company_name,
                company_url: this.addBrand.company_url,
                gstin: this.addBrand.gstin,
                pan: this.addBrand.pan,
                legal_name: this.addBrand.legal_name,
                address: this.addBrand.address
              }
              if (value) {
                const response = await brandAdd(data)
                if (response.succ) {
                  this.$bvToast.toast('Brand added successfully', {
                    title: 'Added Brand',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.resetBrand()
                  this.$refs.addModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add brand error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel add.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
